import {
  StationGroup,
  StationObject,
  StationObjectGroup,
  StationMap,
  StationObjectMap
} from '@/commons/models';

export default class Stations {
  stationsObjects: Array<StationObjectGroup> | null = null;
  stationsObjectMap: StationObjectMap | null = null;
  stationsLists: Array<StationGroup> | null = null;
  stationsListMap: StationMap | null = null;

  constructor() {
    return this;
  }

  public withObject(stations: Array<StationObjectGroup>): Stations {
    this.stationsObjects = stations;
    this.stationsObjectMap = Stations.getStationsObjectMapByDeparture(stations);
    return this;
  }

  public withList(stations: Array<StationGroup>): Stations {
    this.stationsLists = stations;
    this.stationsListMap = Stations.getStationsMapByDeparture(stations);
    return this;
  }

  public static getStationsMapByDeparture(stations: Array<StationGroup>): StationMap {
    return stations.reduce((acc: {[key: string]: StationGroup}, curr: StationGroup) => {
      acc[curr.departureStation] = curr;
      return acc;
    }, {});
  }

  public static getArrivalStationsList(stations: Array<StationGroup>): Array<string> {
    let arrivalList: Array<string> = [];
    stations.forEach((station) => {
      arrivalList = [... new Set([...arrivalList, ...station.arrivalStations])];
    });
    return arrivalList;
  }

  public static getDepartureStationsObject(stations: Array<StationObjectGroup>): Array<StationObject> {
    return stations.map((station) => {
      return station.departureStation;
    })
  }

  public static getStationsObjectMapByDeparture(stations: Array<StationObjectGroup>): StationObjectMap {
    return stations.reduce((acc: {[key: string]: StationObjectGroup}, curr: StationObjectGroup) => {
      acc[curr.departureStation.id] = curr;
      return acc;
    }, {});
  }

  public getDepartureStationObjectById(id: string): StationObject | null {
    return this.stationsObjectMap?.[id]?.departureStation || null;
  }

  public getStationFullName(id: string): string | null{
    const station = this.getDepartureStationObjectById(id);
    return station && `${station.name} (${station.description})`;
  }

  public getStationName(id: string): string | null{
    const station = this.getDepartureStationObjectById(id);
    return station && `${station.name}`;
  }
}
