
import Vue, { PropType } from 'vue';
import Events from '@/commons/constants/events';
import { MultiOptionPlanItem, MultiOptionPlanData } from '@/commons/models';
import Form from '@/commons/components/form/Form.vue';
import Radio from '@/commons/components/form/Radio.vue';

export default Vue.extend({
  name: 'MultiOptionPlan',
  components: {
    Form,
    Radio
  },
  props: {
    /**
     * Plan ID
     */
    planId: {
      type: [String, Number],
      required: true
    },
    /**
     * Url of the image to ilustrate the plan. Optional.
     */
    imageUrl: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Whether to show a message with the recurrency or not
     */
    showPaymentRecurrency: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Whether to show a more information link or not
     */
    showMoreInfoLink:  {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * A text for the link that provides more info.
     */
    linkText: {
      type: String,
      required: false,
    },
    /**
     * The id of the item selected by default. Optional.
     */
    defaultItemId: {
      type: String,
      required: false,
      default: null
    },
    /**
     * An array of PlanItems. Each element of the array will be an option within the plan.
     */
    planItems: {
      type: Array as PropType<MultiOptionPlanItem[]>,
      required: true
    },
  },
  data(): MultiOptionPlanData {
    return {
      selectedItemId: this.defaultItemId || this.planItems[0].id,
    };
  },
  computed: {
    currentItem() {
      const currentItem = (this.planItems as Array<MultiOptionPlanItem>).find(
        item => item.id === this.selectedItemId
      );

      return currentItem;
    },
    currentPrice(): string | number {
      return this.currentItem?.price || '';
    },
    currentRecurrency(): string | number {
      return this.$t(`plans.family.${this.planId}.recurrency`.toLowerCase());
    },
    i18nKey(): string {
      return `plans.${this.currentPlanName}`;
    },
    planItemsI18n() {
      return this.planItems.map((item: MultiOptionPlanItem) => ({
        ...item,
        name: this.$t(`plans.${item.name}.name`.toLowerCase())
      }));
    },
    currentPlanName() {
      return (this.currentItem?.name || '').toLowerCase();
    }
  },
  methods: {
    handleLinkClick() {
      /**
       * Emits the plan selected when the user clicks the link
       *
       * @event info
       */
      this.$emit(Events.INFO, this.planId);
    },
    handleSubmit() {
      /**
       * Emits the plan selected when the user clicks the button
       *
       * @event submit
       * @property {MultiOptionPlanPayload}
       */
      this.$emit(Events.SUBMIT, this.selectedItemId);
    },
    isSingleOption() {
      return this.planItems.length === 1;
    },
  },
});
