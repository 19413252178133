import Vue, { PropType } from 'vue';
import { localize, extend, configure } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';
import {
  alpha,
  alpha_spaces as alphaSpaces,
  alpha_num as alphaNum,
  required,
  email,
  confirmed,
  numeric,
  min,
  max,
  min_value as minValue,
  max_value as maxValue,
  digits,
  regex,
  length,
} from 'vee-validate/dist/rules';
import locale from '@/commons/mixins/form/locale';
import { yearsAgo } from '@/commons/utils/datetime';
import {
  CustomValidation,
  FormLocaleCode,
  ValidationItem,
} from '@/commons/models';
import { isAfter } from 'date-fns';

/**
 * Adds a custom validator to the list of validation rules.
 *
 * @see https://vee-validate.logaretm.com/v3/guide/rules.html#rules
 */
function addValidations(customValidations?: CustomValidation): void {
  extend('alpha', { ...alpha });
  extend('alpha_spaces', { ...alphaSpaces });
  extend('alpha_num', { ...alphaNum });
  extend('confirmed', { ...confirmed });
  extend('digits', { ...digits });
  extend('email', { ...email });
  extend('max', { ...max });
  extend('max_value', { ...maxValue });
  extend('min', { ...min });
  extend('min_value', { ...minValue });
  extend('numeric', { ...numeric });
  extend('required', { ...required });
  extend('alpha_num_space', {
    validate: (value) => {
      const regex = new RegExp('^[\\w\\s]+$');
      return regex.test(value);
    },
  });
  extend('strong_password', {
    /**
     * Mandatory: 1 Uppercase. 1 lowercase. 1 special char. 1 number. 8 length
     */
    validate: (value) => {
      const strongRegex = new RegExp(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
      );
      return strongRegex.test(value);
    },
  });
  extend('regular_password', {
    /**
     * Mandatory: 1 Uppercase. 1 lowercase. 1 number. 8 length
     */
    validate: (value) => {
      const regularRegex = new RegExp(
        '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)[A-Za-z\\d\\W][^\\n ]{7,}$'
      );
      return regularRegex.test(value);
    },
  });
  extend('not_underage', {
    params: ['age'],
    /**
     * Validates that a given date was at least 18 years ago.
     */
    validate: (value, ruleParams) => {
      const DEFAULT_AGE = 18;
      const age = (ruleParams as { age: string }).age;
      const ageLimit = age ? parseInt(age) : DEFAULT_AGE;
      if (value) {
        return yearsAgo(new Date(value)) >= ageLimit;
      } else {
        return false;
      }
    },
  });
  extend('future_date', (value) => {
    return isAfter(new Date(value), new Date());
  });
  extend('regex', { ...regex });
  extend('length', { ...length });

  if (customValidations) {
    // register custom validations dynamically
    for (const field in customValidations) {
      const currentValidation = customValidations[field] as ValidationItem;

      const message =
        typeof currentValidation.message === 'function'
          ? currentValidation.message
          : () => currentValidation.message as string;

      let validate;
      if (currentValidation.regex) {
        validate = (value: string) => {
          const customRegex = new RegExp(currentValidation.regex as string);
          return customRegex.test(value);
        };
      }

      if (currentValidation.validate) {
        validate = currentValidation.validate;
      }

      extend(`custom_${field}`, {
        message,
        validate,
        params: currentValidation.params,
        computesRequired: !!currentValidation.computesRequired,
      });
    }
  }
}

/**
 * Load locale messages
 *
 * @param {string} code - The languague code
 * @see https://vee-validate.logaretm.com/v3/guide/localization.html
 * @returns {Promise<void>}
 */
function loadLocale(code: FormLocaleCode): void {
  if (code.includes('-')) {
    code = code.split('-')[0] as FormLocaleCode;
  }

  const localeData = locale[code];

  if (localeData) {
    localize(code, localeData);
  } else {
    localize(code, locale.en);
  }
}

/**
 * Configure classes
 *
 * @see https://vee-validate.logaretm.com/v3/guide/state.html#css-classes
 */
function configureClasses(): void {
  configure({
    classes: {
      valid: 'is-success',
      invalid: 'help is-danger',
    },
  });
}

/**
 * Create field name
 *
 * @param {string} formName - The form name
 * @param {string} fieldName - The field name
 * @returns {string}
 */
function formFieldName(formName: string, fieldName: string): string {
  return `${formName}_${fieldName}`;
}

export default Vue.extend({
  components: {
    ValidationObserver,
  },
  props: {
    /**
     * Form namespace
     */
    formNamespace: {
      required: true,
      type: String,
    },
    /**
     * Form errors locale
     */
    errorsLocale: {
      required: false,
      type: String as PropType<FormLocaleCode>,
      default: 'en',
    },
    /**
     * Object for custom validations.
     */
    customValidations: {
      required: false,
      type: Object as PropType<CustomValidation>,
      default: null,
    },
  },

  methods: {
    /**
     * Form validator with Vee Validate
     *
     * @param {string} code - The languague code
     *
     * @see https://vee-validate.logaretm.com/v3
     */
    formValidator(code: FormLocaleCode): void {
      configureClasses();
      addValidations(this.customValidations);
      loadLocale(code);
    },

    /**
     * Get field name
     *
     * @param {string} value - The field name
     */
    getFieldName(value: string): string {
      return formFieldName(this.formNamespace, value);
    },
  },
});
