import {
  CatalogPlan,
  CatalogPlanPrice,
  PhasePrice,
  PlansByFamily,
  MappedPlan,
} from '@/commons/models';
import { Resource, viewResources } from '@/commons/utils';
import currencyInstance from '@/commons/modules/currency/currencyInstance';
import { FormatterOptions } from '@/commons/modules/currency/Currency';
import PlansService from '@/commons/services/PlansService';

export default class Plans {
  plans: CatalogPlan[];
  currency: string;

  constructor(plans: PlansByFamily, currency: string) {
    this.plans = Object.values(plans).flat() as CatalogPlan[];
    this.currency = currency;
  }

  static formatPlanCurrency<T extends MappedPlan>(plan: T, options: FormatterOptions = {}) {
    if (!plan?.planItems) return [];

    plan.planItems = plan.planItems.map((item) => {
      const mappedItem = {
        ...item,
        priceRaw: Number(item.price),
        price: currencyInstance.format(Number(item.priceRaw || item.price), options),
      };

      if (item.fullPrice) {
        mappedItem.fullPrice = currencyInstance.format(Number(item.fullPrice), options);
      }

      return mappedItem;
    });

    return plan;
  }

  static getPlanItemsFromFamily<T extends MappedPlan>(mappedPlans: Array<T>, planFamily: string) {
    return mappedPlans
      .find((plan) => plan.planId === planFamily)
      ?.planItems || [];
  }

  static submitPlanSelection(planId: string) {
    const url = viewResources.get<Resource>('url').get<string>('plansRoute');
    if (!url) return;

    window.location.assign(`${url}/${planId}`);
  }

  static submitPlanFamilySelection(planId: string) {
    const url = viewResources.get<Resource>('url').get<string>('plansRoute');
    if (!url) return;

    window.location.assign(`${url}?planFamily=${planId}`);
  }

  static async savePlanSelection(planId: string) {
    await PlansService.selectPlan(planId);
  }

  public getLowestPlanPrice() {
    const priceList: Array<CatalogPlanPrice> = this.plans.map(
      (plan: CatalogPlan) => plan.price
    );
    const lowestFares = priceList.map((price: CatalogPlanPrice) => {
      const fareList = Object.values(price);
      const cheapestFare = fareList.reduce(
        this.getCheapestFare.bind(this),
        fareList[0]
      );
      return cheapestFare[this.currency].qty;
    });
    return Math.min.apply(Math, lowestFares);
  }

  protected getCheapestFare(fare1: PhasePrice, fare2: PhasePrice) {
    return fare1[this.currency] < fare2[this.currency] ? fare1 : fare2;
  }
}
