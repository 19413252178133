enum Events {
  ACTION_CLICK = 'action-click',
  ADD = 'add',
  API_ERROR = 'api-error',
  APPLY = 'apply',
  APPLY_COUPON = 'apply-coupon',
  APPLY_LANGUAGE = 'apply-language',
  AUTOCOMPLETE_DESTINATION = "autocomplete-destination",
  AUTOCOMPLETE_ORIGIN = "autocomplete-origin",
  BACK = 'back',
  CALENDAR_CHANGE = "calendar-change",
  CANCEL = 'cancel',
  CANCEL_PLAN = 'cancel-plan',
  CANCEL_UPDATE = 'cancel-update',
  CHANGE = 'change',
  CHANGE_CARD = 'change-card',
  CHANGE_CARD_CLOSED = 'change-card-closed',
  CHANGE_PLAN = 'change-plan',
  CLICK = 'click',
  CLOSE = 'close',
  CONTINUE = 'continue',
  DELETE = 'delete',
  DATE_SELECT = "date-select",
  DOWNLOAD = 'download',
  EDIT = 'edit',
  EDIT_MODE = 'edit-mode',
  EDIT_PASSENGER = 'edit-passenger',
  EDITION_CANCEL = 'edition-cancel',
  EDITION_SUCCESS = 'edition-success',
  FORGOT_CLICK = 'forgot-click',
  HOLDER_NAME = 'holder-name',
  INFO = 'info',
  INPUT = 'input',
  INVALID = "invalid",
  LABEL_CLICK = 'label-click',
  LINK_CLICK = 'link-click',
  LOGIN = 'login',
  LOGIN_CLICK = 'login-click',
  LOGOUT = 'logout',
  MODAL_CLOSE = 'modal-close',
  NEXT = 'next',
  OPEN = 'open',
  PREVIOUS = 'previous',
  RETRY = 'retry',
  SAVE = 'save',
  SEARCH = 'search',
  SELECT = 'select',
  SHOW_EDIT_PASSENGER = 'show-edit-passenger',
  SHOW_EDIT_PAYMENT_CARD = 'show-edit-payment-card',
  SUBMIT = 'submit',
  TOGGLE_CHECK = 'toggle-check',
  TOGGLE_PANEL = 'toggle-panel',
  USER_AREA_CLICK = 'user-area-click',
  VIEW = 'view',
  VIEW_LOADED = 'view-loaded',
  DOWNLOAD_LATEST_INVOICE = 'download-latest-invoice',
  VIEW_INVOICES = 'view-invoices',
}

export default Events;
