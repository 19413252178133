import Vue from 'vue';
import 'cvo-library';
import { viewResources } from '@/commons/utils/viewResources';
import getI18nConfig from '@/commons/config/i18n';
import { enUS as en, it, pl } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import currencyInstance from '@/commons/modules/currency/currencyInstance';

viewResources.addGeneric('url').setValues(window.CVO.urls);

const carrier = window.CVO.tenantCarrier as string;
const currency = window.CVO.activeCurrency as string;
const language = window.CVO.currentLanguage as string;

currencyInstance.setConfig(carrier, currency, language);

/**
 * @function createApp()
 * @param options.skipSetDateFnsDefaultOptions this attribute (only used at the availability entrypoint) prevents languages of "vue2-datepicker" from colliding with those of "date-fns" and causing errors.
 * Please remove this flag, once the <cvo-search-flight> component is removed and it stopped using "vue2-datepicker" library at availability template.
 */
export default function createApp({ skipSetDateFnsDefaultOptions = false, ...options}: Record<string, any>) {
  getI18nConfig(language).then((i18n) => {
    if (!skipSetDateFnsDefaultOptions) {
      setDefaultOptions({ locale: { en, it, pl }[language] });
    }
    new Vue({ ...options, i18n })
  });
}
