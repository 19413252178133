
import Vue from 'vue';
import Events from '@/commons/constants/events';

export default Vue.extend({
  name: 'Accordion',

  model: {
    prop: 'isOpen',
    event: Events.CHANGE
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    isDefaultOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      internalOpen: this.isDefaultOpen,
    };
  },

  computed: {
    openState() {
      return this.isOpen !== null ? this.isOpen : this.internalOpen;
    },
  },

  methods: {
    toggleOpen() {
      this.$emit(Events.CHANGE, !this.openState);

      if (this.isOpen === null) {
        this.internalOpen = !this.internalOpen;
      }
    },
  }
});
