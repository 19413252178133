import ApiClient from '@/commons/infrastructure/api/apiClient';
import app from '@/commons/modules/app/currentApp';

export default class AppService {
  baseUrl: string;

  constructor() {
    if (!app || !app.tenant) {
      throw new Error('Missing base URL for Application service');
    }
    this.baseUrl = `/${app.tenant}/subscriptions/rest-app`;
  }

  async fetchContext() {
    // Endpoint only available for 'rv' tenant, avoid 404
    if (app.tenant !== 'rv') {
      return {};
    }
    const response = await new ApiClient(this.baseUrl).get('');

    // TODO: error handling;
    return response.data;
  }
}
