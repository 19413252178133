import AppService from '@/commons/services/AppService';
import View from '@/commons/modules/view/View';

type ViewsCollection = {
  [key: string]: View
}

export default class App {
  public tenant: string;
  public views: ViewsCollection;
  public urls: {
    [key: string]: string
  };
  public currency: string;

  constructor(tenant: string) {
    this.tenant = tenant;
    this.views = {};
    this.urls = {};
    this.currency = '';
  }

  public withViews(views: ViewsCollection) {
    this.views = views;
  }

  public async initialize() {
    if (this.tenant === 'undefined' || !this.tenant.length) {
      console.warn('No tenant carrier code configured, some logic may be not working properly');
      return;
    }

    const service = new AppService();

    const data = await service.fetchContext();

    this.urls = data.urls;
    this.currency = data.currency;
  }
}
