
import Vue, { PropType, VueConstructor } from "vue";
import formElement from '@/commons/mixins/form/form-element';
import Events from '@/commons/constants/events';
import { InputType } from '@/commons/models/FormSchema';

const ExtendedVue = Vue as VueConstructor<
  Vue & InstanceType<typeof formElement>
>;

export default ExtendedVue.extend({
  name: "InputField",

  mixins: [formElement],

  model: {
    prop: "value",
    event: Events.INPUT
  },

  props: {
    /**
     * Field model
     */
    value: {
      type: [String, Number],
      default: "",
      required: false
    },
    /**
     * Input type
     */
    type: {
      type: String as PropType<InputType>,
      default: "text",
      required: true,
      validator: function(value: string): boolean {
        return (
          ["text", "email", "password", "tel", "number", "hidden", "date"].indexOf(
            value
          ) !== -1
        );
      }
    },
    /**
     * Field placeholder
     */
    placeholder: {
      type: String,
      default: "",
      required: false
    },
  },

  data() {
    return {
      currentValue: this.value
    };
  },

  methods: {
    /**
     * Emits the input change
     */
    handleInput() {
      /**
       * Event emitted on input change
       *
       * @event input
       * @property {string}
       */
      this.$emit(Events.INPUT, this.currentValue);
    },

    /**
     * Emits the input change
     */
    handleChange() {
      /**
       * Event emitted on input change
       *
       * @event change
       * @property {string}
       */
      this.$emit(Events.CHANGE, this.currentValue);
    },

    /**
     * Sets the proper classes
     * @param {object} classes Vee Validate classes https://vee-validate.logaretm.com/v3/guide/state.html#css-classes
     * @returns {object} new object with classes
     */
    setupClasses(classes: object): object {
      const newClasses = {
        ...classes,
        input: true
      };
      return this.getClasses(newClasses);
    }
  }
});
