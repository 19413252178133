import Vue, { PropType } from 'vue';
import { ValidationProvider } from 'vee-validate';
import Events from '@/commons/constants/events';
import { getUniqueId } from '@/commons/utils/ui';

export default Vue.extend({
  components: {
    ValidationProvider,
  },

  props: {
    /**
     * Vee Validate - rules
     * @see https://vee-validate.logaretm.com/v3/guide/rules.html
     */
    rules: {
      type: [String, Object],
      default: '',
      required: false,
    },

    /**
     * Vee Validate - vid
     * @see https://vee-validate.logaretm.com/v3/advanced/cross-field-validation.html#targeting-other-fields
     */
    vid: {
      type: String,
      required: false,
      default: null,
    },

    /**
     * Field label
     */
    label: {
      type: String,
      default: '',
      required: false,
    },

    /**
     * Field name
     */
    name: {
      type: String,
      default: '',
      required: true,
    },

    /**
     * Field is disabled
     */
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },

    /**
     * Tooltip message
     */
    tooltip: {
      type: String,
      required: false,
      default: '',
    },

    /**
     * Tooltip message
     */
    tooltipIcon: {
      type: String,
      required: false,
      default: null,
    },

    /**
     * Used by vee validate
     */
    validationMode: {
      type: [String, Function],
      required: false,
      default: 'eager',
      validator: function (value: string | Function): boolean {
        if (typeof value === 'string') {
          return (
            ['eager', 'aggressive', 'lazy', 'passive'].indexOf(value) !== -1
          );
        } else if (typeof value === 'function') {
          return true;
        } else {
          return false;
        }
      },
    },

    /**
     * v-model property. optional
     */
    value: {
      type: [String, Number, Object] as PropType<unknown>,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      uniqueId: getUniqueId(),
      currentValue: null as unknown,
      hasFocus: false,
    };
  },

  computed: {
    veeId(): string {
      return this.vid || this.name;
    },

    getRules(): string {
      return this.disabled ? '' : this.rules;
    },

    isRequired(): boolean {
      if(!this.rules) {
        return false;
      }

      return this.rules.indexOf("required") >= 0;
    }, 
  },

  watch: {
    /**
     * Updates the value when prop changes
     * @param {newValue} string
     */
    value: {
      handler(newValue: unknown): void {
        this.valueWatcher(newValue);
      },
    },
  },

  methods: {
    /**
     * Sets the proper classes
     * @param {object} classes Vee Validate classes https://vee-validate.logaretm.com/v3/guide/state.html#css-classes
     * @returns {object} new object with classes
     */
    getClasses(classes: object): object {
      return {
        ...classes,

        // CVO class
        'Form-field': true,
      };
    },

    handleValueChange(newValue: unknown) {
      if (newValue !== this.currentValue) {
        this.currentValue = newValue;
        this.$emit(Events.CHANGE, this.currentValue);
      }
    },

    valueWatcher(newValue: unknown) {
      this.handleValueChange(newValue);
    },

    setFocus(value: boolean): void {
      this.hasFocus = value;
    },
  },
});
