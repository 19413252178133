import { Resource, viewResources } from '@/commons/utils';
import ApiService from '@/commons/services/ApiService';
import { encodeQueryParams } from '@/commons/utils/objects';

export default function getApiService<T>(urlName: string): Record<string, any> {
  const baseUrl = <string>viewResources.get<Resource>('url')?.get(urlName);
  if (!baseUrl) {
    console.error(`No URL found for ${urlName}`);
  }
  const headers = { accept: 'application/vnd.cvo.subs.frontend+json' };
  const apiService = new ApiService({ baseUrl, headers });

  const withQueryParams = (queryParams: T) => {
    encodeQueryParams(queryParams);
    const baseUrl = `${apiService.client.baseUrl}${encodeQueryParams(queryParams, { isUrlQuery: true })}`;
    apiService.client.baseUrl = baseUrl;

    return apiService;
  };

  return {
    ...apiService,
    withQueryParams,
    checkResponse: apiService.checkResponse
  };
}
