import { format, utcToZonedTime } from 'date-fns-tz';
import {
  getDefaultOptions,
  addMonths,
  endOfMonth,
  differenceInDays,
} from 'date-fns';


type DefaultOptions = {locale: Locale, [prop: string]: any};

export function formatDate(date: Date | number | string, dateFormat: string) {
  if (!date || !dateFormat) return '';

  const locale = (getDefaultOptions() as DefaultOptions).locale;

  return format(utcToZonedTime(date, 'UTC'), dateFormat, {
    timeZone: 'UTC',
    locale: locale,
  });
}

export function formatDateForCurrentLocale(date: Date, dateFormat: string) {
  const locale = (getDefaultOptions() as DefaultOptions).locale;
  return format(date, dateFormat, { locale });
}

export function getUTCDateTime(dateString: string, currentDate: Date): string {
  const setTwoDigits = (num: number) => `${num}`.padStart(2, '0');

  const hours = setTwoDigits(currentDate.getHours());
  const min = setTwoDigits(currentDate.getMinutes());
  const seconds = setTwoDigits(currentDate.getSeconds());

  return `${dateString}T${hours}:${min}:${seconds}.000Z`;
}

export function formatUnixTimestampForCurrentLocale(unixTimestamp: number, dateFormat: string = 'dd MMMM yyyy') {
  const date = new Date(unixTimestamp * 1000);
  return formatDateForCurrentLocale(date, dateFormat);
}

export function getStartOfNextMonth() {
  return addMonths(Date.now(), 1).setDate(1);
}

export function getStartOfNextQuarter() {
  return addMonths(Date.now(), 3).setDate(1);
}

export function getStartOfNextPeriod(recurrency: string) {
  switch(recurrency) {
    case 'MONTHLY': return getStartOfNextMonth();
    case 'QUARTERLY': return getStartOfNextQuarter();
    default: return '';
  }
}

export function getDaysUntilEndOfMonth() {
  const today = new Date();

  return differenceInDays(endOfMonth(today), today);
}