
import Vue, { PropType } from 'vue';
import Accordion from '@/commons/components/accordion/Accordion.vue';

export default Vue.extend({
  name: 'AccordionList',
  components: { Accordion },

  data() {
    return {
      accordionList: this.items.map((item) => item.isOpen || false),
    };
  },

  props: {
    items: {
      type: Array as PropType<Array<{ content: String, title: String, isOpen?: boolean }>>,
      required: true,
    },
    multiopen: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onChange(isOpen: boolean, index: number) {
      if(this.multiopen || !isOpen) {
        return;
      }

      this.accordionList.forEach((item: boolean, i: number) => {
        this.accordionList[i] = index !== i ? false : item;
      });
    }
  }
});
