import getApiService from '@/commons/services/FrontendJsonService';
import ApiService from '@/commons/services/ApiService';
import { Resource, viewResources } from '@/commons/utils';
import app from '@/commons/modules/app/currentApp';
import { CancelPlanPayload } from '@/commons/models';

export default class PlansService {
  static async fetchData() {
    const apiService = getApiService('plansRoute');
    const response = await apiService.client.get('');

    apiService.checkResponse(response);

    return response;
  }

  static async fetchPlans() {
    const apiService = getApiService('plansApiRoute');
    const response = await apiService.client.get('');

    apiService.checkResponse(response);

    return response;
  }

  static async selectPlan(planId: string, selectedAddons: string[]) {
    const baseUrl = app.urls.planSelection;

    const headers = { accept: 'application/vnd.cvo.subs.frontend+json' };
    const apiService = new ApiService({ baseUrl, headers });

    const response = await apiService.client.post('', {
      planName: planId,
      ...selectedAddons?.length && { selectedAddons }
    });

    apiService.checkResponse(response);
 }

  static async selectPrivatePlan(planId: string, selectedAddons: string[]) {
    const baseUrl = (<string>viewResources.get<Resource>('url')?.get('do-planSelectionPrivate'))
      .replace('PLAN_ID', planId);

    const headers = { accept: 'application/vnd.cvo.subs.frontend+json' };
    const apiService = new ApiService({ baseUrl, headers });

    const response = await apiService.client.post('', {
      planName: planId,
      ...selectedAddons?.length && { selectedAddons }
    });

	  apiService.checkResponse(response);

    return response;
  }

  static async submitPlanCancelation(payload: CancelPlanPayload) {
    const baseUrl = (<string>viewResources.get<Resource>('url')?.get('cancelSubscription'));

    const headers = { accept: 'application/vnd.cvo.subs.frontend+json' };
    const apiService = new ApiService({ baseUrl, headers });

    const response = await apiService.client.post('', payload);

	  apiService.checkResponse(response);

    return response;
  }
}
