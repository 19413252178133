import BaseFilter from "./BaseFilter";

export default class StringFilter implements BaseFilter{
  name: string;

  constructor(name: string) {
    this.name = name;
  }

  filter(value: string, elements: Array<any>): Array<any> {
    const searchRegex = new RegExp(value, "i");
    return elements.filter((element) => {
      return element[this.name]?.match(searchRegex)
    });
  }
}