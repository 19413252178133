
import Vue from 'vue';
import CarouselSlider from '@/commons/components/carousel-slider/CarouselSlider.vue';
import MultiOptionPlan from './MultiOptionPlan.vue';
import PlansModule from '@/commons/modules/Plans';
import StationsFilterBar from '@/commons/components/filter-bar/StationsFilterBar.vue';
import CatalogModule from '@/commons/modules/Catalog';
import StationFilter from '@/commons/modules/catalog-filters/StationFilter';
import { formatDate } from '@/commons/utils/formatDate';
import { FilterQuery } from '@/commons/models';
import StationsModule from '@/commons/modules/Stations';

export default Vue.extend({
  name: 'W6Plans',

  components: {
    CarouselSlider,
    MultiOptionPlan,
    StationsFilterBar,
  },

  computed: {
    currentDirection() {
      return window.CVO.currentDirection;
    },

    stations() {
      return window.CVO.stations;
    },

    mappedPlans() {
      return this.catalogModule?.filteredPlans || [];
    },

    hasActiveFilters() {
      return CatalogModule.hasQuery(this.currentQuery);
    },

    getSummaryTranslation() {
      let result;
      if(this.currentQuery.departureStation && this.currentQuery.arrivalStations) {
        result = this.$t('plans.filter-results.route', {
          from: this.stationsModule.getStationFullName(this.currentQuery.departureStation),
          to: this.stationsModule.getStationFullName(this.currentQuery.arrivalStations)
        });
      } else {
        result = this.$t('plans.filter-results.station', {
          station: this.stationsModule.getStationFullName(this.currentQuery.departureStation),
        });
      }
      return result;
    }
  },

  data() {
    return {
      stationsModule: null,
      catalogModule: null,
      currentQuery: null,
      isLoading: false,
    }
  },

  async mounted() {
    this.isLoading = true;
    this.stationsModule = this.stations
      ? new StationsModule().withObject(this.stations)
      : null;
    const departureFilter = new StationFilter('departureStation');
    const arrivalFilter = new StationFilter('arrivalStations');

    this.catalogModule = await CatalogModule.build(true);
    this.catalogModule
      .withFilters([departureFilter, arrivalFilter])
      .withMapper(PlansModule.formatPlanCurrency);

    this.isLoading = false;
  },

  methods: {
    filterPlans(query: FilterQuery) {
      this.currentQuery = query;
      this.catalogModule.filter(query);
      if(this.mappedPlans.length) {
        // Wait for vue to update UI
        this.$nextTick(() => {
          this.$refs.carousel.resetCarousel();
        });
      }
    },

    getPlanFamilyI18nKey(planId: string): string {
      return `plans.family.${planId}`.toLowerCase();
    },

    getPlanNameI18nKey(planName: string): string {
      return `plans.${planName}`.toLowerCase();
    },

    submitPlan(planId: string): void {
      PlansModule.submitPlanSelection(planId);
    },

    formatPlanStartDate(date: string) {
      return formatDate(date, 'MMM');
    },
  },
});
