
import Vue, { PropType, VueConstructor } from "vue";
import formElement from '@/commons/mixins/form/form-element';
import Events from '@/commons/constants/events';
import { FormItemList, RadioData } from '@/commons/models/FormSchema';

const ExtendedVue = Vue as VueConstructor<
  Vue & InstanceType<typeof formElement>
>;

export default ExtendedVue.extend({
  name: "Radio",

  mixins: [formElement],

  model: {
    prop: "selectedItem",
    event: Events.CHANGE
  },

  props: {
    /**
     * Sets initial value
     */
    selectedItem: {
      type: [String, Number],
      required: false,
      default: null
    },

    /**
     * Items to display in radio button
     */
    items: {
      type: Array as PropType<FormItemList>,
      required: true
    }
  },

  data(): RadioData {
    return {
      currentValue: this.selectedItem
    };
  },

  watch: {
    selectedItem(newValue: string | number): void {
      this.currentValue = newValue;
    },

    currentValue(): void {
      this.handleChange();
    }
  },

  methods: {
    handleChange() {
      /**
       * Emits the radio item selected
       *
       * @event change
       * @property {string | number}
       */
      this.$emit(Events.CHANGE, this.currentValue);
    },

    /**
     * Sets the proper classes
     * @param {object} classes Vee Validate classes https://vee-validate.logaretm.com/v3/guide/state.html#css-classes
     * @returns {object} new object with classes
     */
    setupClasses(classes: object): object {
      const newClasses = {
        ...classes,
        radio: true
      };
      return this.getClasses(newClasses);
    }
  }
});
